import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { iUserState } from '../platform/redux/userSlice';
import { iServerSession } from '../platform/Server';

interface iProps {
  user: iUserState;
  serverSession: iServerSession;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const UserCard = (props:iProps) : JSX.Element => {
  return (
    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src={props.user.picture} />
      <Card.Body>
        <Card.Title>{props.user.email}</Card.Title>
        <Card.Text>
          Session Valid Until:<br/>{new Date( props.serverSession.expires).toLocaleString()}
        </Card.Text>
        <Button variant="primary" onClick={props.onClick}>Logout</Button>
      </Card.Body>
    </Card>
  );
}

export default UserCard;