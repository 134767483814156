import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iServerError } from '../Server';


export interface iAppErrorState {
  isError: boolean;
  type: string;
  error: iServerError['error']|null;
}

const initialState = {
  isError: false,
  type: "Server",
  error: null
} satisfies iAppErrorState as iAppErrorState;

export const errorSlice = createSlice({
  name: 'appError',
  initialState,
  reducers: {
    setAppError: (state, action: PayloadAction<iServerError>) => {
      state.isError = true;
      state.error = action.payload.error;
    },
    setAppErrorMsg: (state, action: PayloadAction<string>) => {
      state.isError = true;
      state.error = {code: 500, message: action.payload};
    },
    clearAppError: (state ) => {
      state.isError = false;
      state.error = null;
    },
  }
});

const { setAppError, clearAppError, setAppErrorMsg } = errorSlice.actions;
export { setAppError, setAppErrorMsg, clearAppError};
export default errorSlice.reducer;