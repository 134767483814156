import { Image} from 'react-bootstrap';
import { iUserState } from '../platform/redux/userSlice';

interface iProps {
  user: iUserState;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const UserNavCard = (props:iProps) : JSX.Element => {
  return (
    <>
    {
      props.user.email ?
        <div className='App-header-profile'>
          <Image src={props.user.picture} roundedCircle height={25} />
          { (props.user.email ? props.user.email.replace(/@.*/,'') : "") }
        </div>
      :
        "Account"
    }
    </>
  );
}

export default UserNavCard;