import { KeyboardEvent, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { HashIDKey } from '../platform/redux/tradeSlice';

interface iProps {
  onSelected(val:number): void;
  data: HashIDKey;
  showAll?: boolean;
  cssID?: string;
  value?: number;
}

const DropDownIDList = (props:iProps) : JSX.Element => {
  const {onSelected, showAll, value, data, cssID} = props;
  const [selectItem, setSelectItem] = useState( value!==undefined ? value : 0);

  const title = value !== undefined ? data[value] : "";
  const keyData = Object.entries(data).map(str=>{
    return [ str[1].charAt(0).toLowerCase(),  parseInt(str[0]) ] 
  });

  const onSelect = (ek:any, event:Object) => {
    setSelectItem( ek as number)
    onSelected( ek as number);
  }

  /*
    Allow users to enter a key to search values. ID List is 0~n with string vals.
    First we created two-dimensional array with first char as first element, then position as second.
    If multiple entries have the same letter than:
      Find first and go
      If on same letter than start from position and go next
      If on last than start over some how
  */
  const keyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    let found = keyData.find(sa=>sa[0]===event.key); //Find first occurence

    if( found===undefined ) return;
    if( showAll !== true && +found[1] === 0 ) return null;

    //If we are already on a found item then find next or revert to first.
    if( data[selectItem].charAt(0).toLocaleLowerCase() === event.key ) {
      let foundNext = keyData.find(sa=>{ if( +sa[1] > selectItem) {return sa[0]===event.key} else {return null} });
      if( foundNext !== undefined ) found = foundNext;
    }

    onSelect( found[1].toString(), {});
  }

  const item = (key:number) => {
    if( showAll !== true && +key === 0 ) return null;
    return <Dropdown.Item key={key} eventKey={key} active={key===selectItem ? true : false} >{data[key]}</Dropdown.Item>
  }

  return (
    <Dropdown>
      <DropdownButton title={title} id={cssID || "dropDownList"} onSelect={onSelect} onKeyUp={keyUp}>
        {
          Object.keys(data).map( (key:any) => {
            return item(key as number);
          })
        }
    </DropdownButton>
    </Dropdown>
  );
}

export default DropDownIDList;