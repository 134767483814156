import { useEffect, useState } from "react";
import { Container, Spinner, Table } from "react-bootstrap";
import { RootState } from "../platform/redux/store";
import { useAppDispatch } from "../hooks";
import { connect } from "react-redux";
import { getCongressAssets, iCongressState, typeCongressAsset } from "../platform/redux/congressSlice";
import { useParams } from "react-router";
import FormattedDate from "../components/FormattedDate";
import { Link } from "react-router-dom";

interface iCongress {
  congress: iCongressState
}

const Congress = (props:iCongress) => {
  const {congress} = props ;
  const [isBusy, setIsBusy] = useState(false);
  const [assets, setAssets] = useState([] as typeCongressAsset[]);
  const dispatch = useAppDispatch();  

  const {congress_id}  = useParams();
  const congressID:number = parseInt(congress_id!);

  useEffect(() => {
    if (congress.congressAsset[congressID] === undefined && congress.isLoading === false && congress.error === null) {
      setIsBusy(true);
      dispatch( getCongressAssets(congressID) );
    }
  //eslint-disable-next-line
  },[]);

  useEffect(() => {
    setAssets ( 
      congress.congressAssets.filter((a) => {
        if( a.congress_id === congressID ) {
          return true;
        } else {
          return false;
        }
      })
    );
    setIsBusy(false)
  // eslint-disable-next-line
  },[congress.congressAssets]);


  const CongressHeader = () => {
    return (
      <thead>
        <tr style={{verticalAlign: "middle" }}>
          <th>Action</th>
          <th>Asset</th>
          <th>Type</th>
          <th>Owner</th>
          <th>Min</th>
          <th>Max</th>
          <th>DOC ID</th>
          <th>Filing Date</th>
          <th>Raw text</th>
        </tr>
      </thead>
    )
  }

  interface iCongressRow {
    data: typeCongressAsset;
  }
  const CongressRow = (props:iCongressRow) => {
    const {data} = props;
    return (
      <tr>
        <td>{data.action}</td>
        <td>{data.asset}</td>
        <td>{data.asset_code}</td>
        <td>{data.owner}</td>
        <td>{data.min}</td>
        <td>{data.max}</td>
        <td><Link target="_new" to={data.congress_filing.url}>{data.congress_filing.docid}</Link></td>
        <td><FormattedDate date={data.congress_filing.filing_date}/></td>
        <td>{data.order_text}</td>
      </tr>
    );
  }

  return (
    <>
    <Container>
      <Table striped bordered hover size="sm" responsive="true">
      <CongressHeader />
        {isBusy && <Spinner />}
        <tbody>
        {
          assets.map(cong => {
            return <CongressRow data={cong} />
          })
        }
        </tbody>
      </Table>
    </Container>
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  congress: state.congress as iCongressState,
});

const CongressContainer = connect(mapStateToProps)(Congress)
export default CongressContainer;