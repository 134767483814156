import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iServerError } from '../Server';
import { setAppError } from './appErrorSlice';
import { consoleLog } from '../config/platform';

interface iTradeSources {
  id:   number;
  name: string;
}
interface iStructures {
  id:   number;
  name: string;
}
export interface iAttributeTables {
  trade_sources: iTradeSources[];
  structures: iStructures[];
}

type HashIDKey = {
  [key: number]: string;
}

export var tradeSources = {} as HashIDKey;

export interface iAttributes{
  loaded: boolean;
  trade_sources: HashIDKey;
  structures: HashIDKey;
}

export type HashCharKey = {
  [key: string]: string;
}

const saveState = (state:iAttributes) => {
  var sState = {
    trade_sources: state.trade_sources,
    structures: state.structures
  }
  consoleLog("saveState.cookie: ", JSON.stringify(sState));
  sessionStorage.setItem('atts', JSON.stringify(sState) );
}

export const attState = () : iAttributes => {
  var uState = sessionStorage.getItem('atts') || "";
  if( uState === "" ) {
    return {
      loaded: false,
      trade_sources: {},
      structures: {} 
    }
  } else {
    var atts = JSON.parse(uState);
    tradeSources = atts.trade_sources;
    consoleLog("attributeSlice.atts: ", atts.trade_source);
    return atts;
  }
}
const initialState: iAttributes = attState();

export const attributeSlice = createSlice({
  name: 'attributes',
  initialState,
  reducers: {
    loaded: (state, action: PayloadAction<iAttributeTables>) => {
      state.loaded = true;
      state.trade_sources[0] = 'All';
      action.payload.trade_sources.map((ts) => {
        state.trade_sources[ts.id] = ts.name;
        return true;
      });
      action.payload.structures.map((st) => {
        state.structures[st.id] = st.name;
        return true;
      });
      saveState(state);
    },
    clear: (state) => {
      state.loaded = false;
      state.trade_sources = {};
      state.structures = {};
      sessionStorage.removeItem("atts");
    },
  }
});

const { loaded, clear } = attributeSlice.actions;
export default attributeSlice.reducer;

const attributesLoad = createAsyncThunk(
  "attributes/load",
  async (atts:iAttributeTables, {dispatch, rejectWithValue}) => {
    try {
      console.log("Here loading attributes")
      console.log(atts);
      dispatch( loaded(atts) );
    } catch(err) {
      dispatch( setAppError(err as iServerError) );
      return rejectWithValue(err);
    }
  }
);

const attributesClear = createAsyncThunk(
  "attributes/clear",
  async (_, {dispatch, rejectWithValue}) => {
    try {
      dispatch( clear() );
    } catch(err) {
      dispatch( setAppError(err as iServerError) );
      return rejectWithValue(err);
    }
  }
);

export {attributesLoad, attributesClear};