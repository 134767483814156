
//Calling function must use await sleep(2)
export const sleep = async (sec:number) => {
  const doSleep = (ms:number) => new Promise(r => setTimeout(r, ms));
  await doSleep( sec * 1000 );
};

export const nextFriday = () :Date => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const daysUntilFriday = (5 - dayOfWeek + 7) % 7 || 7;

  today.setDate(today.getDate() + daysUntilFriday);
  return new Date(today);
}