import { Link, Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import  {RootState} from './platform/redux/store';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Container } from 'react-bootstrap';
import { authRefreshSession, serverTokenLogout } from './platform/redux/userSlice';
import { consoleLog, platformConfig } from './platform/config/platform';
import { useAppDispatch } from './hooks';
import { useEffect } from 'react';

import './App.css';

import Home from './pages/Home';
import Account from './pages/Account';
import Trades from './pages/Trades';
import UserNavCard from './components/UserNavCard';
import ErrorCard from './components/ErrorCard';
import TradeShow from './pages/TradeShow';
import TradeEdit from './pages/TradeEdit';
import Congresses from './pages/Congresses';
import Congress from './pages/Congress';
import Funds from './pages/Funds';
import Fund from './pages/Fund';
import Thirteenf from './pages/Thirteenf';
import Tos from './pages/Tos';
import { iServerError, serverSessionValid } from './platform/Server';

function App() {
  const dispatch = useAppDispatch();
  const appError = useSelector((state:RootState) => state.appError);
  const user = useSelector((state:RootState) => state.user);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if( location.pathname === '/account') return;
    sessionStorage.setItem("lastPage", location.pathname);
  },[location]);

  useEffect(() => {
    if( appError.error?.code === 412 ) {
      dispatch( serverTokenLogout() ).then(()=>navigate('/account'));
    }

    if( appError.error?.code === 401 ) {
      navigate('/account');
    }
    // eslint-disable-next-line
  },[appError.error]);

  /*
    Check every minute if we need to refresh our client token.
    Should expire in 5 minutes. The overall session is good for x hours
  */
  useEffect(() => {
    let intVal:any;
    consoleLog("App.useEffect()[]");

    const runTimeout = async () => {
      // intVal = setInterval( ()=>dispatch( authRefreshSession() ), 30000);
      if( !user.isLoggedIn) return;

      const resp = (await dispatch( authRefreshSession() )).payload as iServerError ;
      if( resp.error.code === 401 ) return;

      if(intVal) clearTimeout(intVal)
      intVal = setTimeout(runTimeout, 30000) ;
    }

    consoleLog("  App.useEffect()[] define handleVisibilityChange " + intVal);
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        runTimeout();
      }
    };

    // const handleFocus = () => {
      // consoleLog("  Focus Change: intVal? " + intVal);
    // };
    // window.addEventListener("focus", handleFocus);

    document.addEventListener("visibilitychange", handleVisibilityChange);
    runTimeout();

    return ()=>{
      consoleLog("  App.useEffect()[].return: " + intVal); 
      clearTimeout(intVal)
    };
    // eslint-disable-next-line
  },[user.isLoggedIn]);

  const PrivateRoutes = () => {
    return (
      (serverSessionValid() && user.isLoggedIn && appError.error?.code !== 401) ? <Outlet /> : <Navigate to="/account" />
    )
  }

  const devStyle = platformConfig.production ? "none" : "1px solid red" ;

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary" sticky="top" style={{border: devStyle}}>
      <Container>
        <Navbar.Brand href="/">Trade Tracker</Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
           <Link className='nav-link' to="/trades">My Trades</Link>
           <Link className='nav-link' to="/congresses">Congress</Link>
           <Link className='nav-link' to="/funds">Funds</Link>
          </Nav>
          <Nav className="ms-auto">
            <Link className='nav-link' to="/account"> <UserNavCard user={user} /> </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <ErrorCard />
    <br/>

      <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path="/account" element={ <Account /> } />
        <Route element={<PrivateRoutes/>}>
          <Route path="/trades" element={ <Trades /> } />
          <Route path="/trades/:trade_id/:trade_source_id" element={ <TradeShow /> } />
          <Route path="/trades/:trade_id/edit" element={ <TradeEdit /> } />
          <Route path="/trades/new" element={ <TradeEdit /> } />
          <Route path="/congresses" element={ <Congresses /> } />
          <Route path="/congress/:congress_id" element={ <Congress /> } />
          <Route path="/funds" element={ <Funds /> } />
          <Route path="/tos" element={ <Tos /> } />
          <Route path="/funds/:fund_id" element={ <Fund /> } />
          <Route path="/thirteenfs/:thirteenf_id" element={ <Thirteenf /> } />
        </Route>
      </Routes>
  </>
  );
}

export default App;