import { Alert, Container } from 'react-bootstrap';
import { RootState } from '../platform/redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { clearAppError } from '../platform/redux/appErrorSlice';

const ErrorCard = () : JSX.Element => {
  const dispatch = useDispatch();
  const appError = useSelector((state:RootState) => state.appError)

  if( appError.isError !== true ) return <></>;

  return (
    <Container>
      <Alert variant="danger" onClose={() => dispatch(clearAppError())} dismissible> 
        <Alert.Heading>Error {appError.error?.code}</Alert.Heading> 
          <p>{appError.error?.message }</p> 
      </Alert> 
    </Container>
  );
}
export default ErrorCard;