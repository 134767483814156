import { KeyboardEvent, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../platform/redux/store";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.min.css'

interface iEditNumberField {
  date: Date|null;
  onChanged(date:Date|null):void;
  useOrigTime?: boolean;
  noSpinner?: boolean;
  className?: string;
}

const EditDateField = (props:iEditNumberField) => {
  const {date,onChanged,useOrigTime, noSpinner, className} = props;
  const [inputDate,setInputDate] = useState( date!==null ? new Date(date) : null);
  const [isUpdating,setIsUpdating] = useState(false);
  const clsName = (className || "dateField");

  const tradeState = useSelector((state:RootState) => state.trade )
  useEffect(() => {
    if( !tradeState.isLoading ) setIsUpdating(false);
  },[tradeState.isLoading]);

  const dateChange = (nDate:Date|null) => {
    if( useOrigTime && date && nDate !== null ) {
      nDate.setHours( date.getHours() );
      nDate.setMinutes( date.getMinutes() );
      nDate.setSeconds( date.getSeconds() );
    }
    setInputDate( nDate );
    if(noSpinner !== true) setIsUpdating(true);
    onChanged(nDate);
  }

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape" || event.key === "Tab") {
      event.currentTarget.value = date!==null ? date.toString() : ""; //Reset to original
      event.currentTarget.blur();
      return;
    }
    if (event.key === "Enter") {
      event.currentTarget.value === "" ?  dateChange(null) : dateChange(new Date(event.currentTarget.value));
    }
  }

  return (
    isUpdating ?  <Spinner animation="border" role="status" /> :
      <DatePicker
        id="editTextField"
        className={clsName}
        startDate={inputDate}
        dateFormat="MM/dd/yyyy"
        placeholderText="Enter/Select Date"
        monthsShown={1}
        selected={inputDate ? new Date(inputDate) : null}
        onSelect={(nDate) => { if (nDate !== null) { dateChange( nDate ) } }}
        onKeyDown={onKeyDown}
        onChange={(nDate) => { }}
      /> 
  );

}

export default EditDateField;