import * as development from './development';
import * as production from './production';

interface iPlatformConfig {
  apiKey: string;
  apiUrl: string;
  production: boolean;
}

let platformConfig: iPlatformConfig;

if( production.productionHosts.includes(window.location.hostname) ) {
  platformConfig = production.platformConfig;
} else {
  platformConfig = development.platformConfig;
}

const consoleLog = (msg:string, ...args:any) => {
  if( platformConfig.production ) return;
  console.log((new Date().toLocaleString()) + " " + msg, args);
}

export { platformConfig, consoleLog};