import { ChangeEvent, KeyboardEvent, ReactElement, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../platform/redux/store";

export enum EditNumberFieldFormats{
  count,
  whole,
  decimal
}

interface iEditNumberField {
  num: number;
  format: EditNumberFieldFormats;
  isLoading?: boolean;
  onChanged(number:number):void;
  children?: ReactElement;
}

const EditNumberField = (props:iEditNumberField) => {
  const {num,onChanged,isLoading,format} = props;
  const [inputNumber,setInputNumber] = useState(num.toString());
  const [isUpdating,setIsUpdating] = useState(isLoading);
  const [isEditing,setIsEditing] = useState(isLoading);

  const tradeState = useSelector((state:RootState) => state.trade )
  useEffect(() => {
    if( !tradeState.isLoading ) setIsUpdating(false);
  },[tradeState.isLoading]);


  const validate = (val:any) => {
    var reg = new RegExp( format===EditNumberFieldFormats.decimal ? '^-*\\d*\\.*\\d*$' :  '^[0-9]+$' );
    return reg.test(val) ;
  }

  const txtChange = (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.currentTarget.value;
    if( validate(val) || val==="") {
      setInputNumber( val );
    }
  }

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if( validate(event.key)===false && event.key.length===1 ) {
      event.preventDefault();
      return
    }

    if (event.key === "Escape" || event.key === "Tab") {
      event.currentTarget.value = num.toString(); //Reset to original
      event.currentTarget.blur();
      return;
    }

    if (event.key === "Enter") {
      const passVal = inputNumber.trim() === "" ? "0" : inputNumber;
      if( !validate(passVal) ) {
        alert("Bad number!"); return;
      }

      setIsUpdating(true);
      event.currentTarget.blur();
      onChanged( format===EditNumberFieldFormats.decimal ? parseFloat(passVal) : parseInt(passVal) )
    }
  }

  const valWithFormat = (val:any) => {
    if( format===EditNumberFieldFormats.count ) return val;
    let num = Math.round( parseFloat(val) * 100 + Number.EPSILON ) / 100;
    let numFormat = new Intl.NumberFormat('en-US', { 
      minimumIntegerDigits: 1, 
      minimumFractionDigits: format ? 0 : 2 
    });
    return num < 0 ? "(" + numFormat.format(num) + ")" :numFormat.format(num);
  }

  return (
    isUpdating ?  <Spinner animation="border" role="status" /> :
    <input id="editTextField" type="text" aria-label="name" style={{ width: inputNumber.toString().length * 10 + "px" }}
      value={isEditing ? inputNumber : valWithFormat( inputNumber ) }
      onChange={txtChange}
      onKeyDown={onKeyDown}
      onFocus={() => setIsEditing(true)}
      onBlur={() => setIsEditing(false)}
    />
  );

}

export default EditNumberField;