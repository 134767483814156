import { useEffect, useState } from "react";
import { Container, Table, Form, Spinner, Button } from "react-bootstrap";
import { RootState } from "../platform/redux/store";
import { callPut, insertTrade, iTradesState, tradeStatuses, tradeTypes, typeTrade, updateTrade } from "../platform/redux/tradeSlice";
import { useAppDispatch } from "../hooks";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router";
import EditDateField from "../components/EditDateField";
import DropdownList from "../components/DropdownList";
import DropDownIDList from "../components/DropdownIDList";
import {nextFriday} from "../platform/Utils"

interface iTrades {
  trade: iTradesState,
}

const TradeEdit = (props:iTrades) => {
  const {trade} = props;
  const {trade_id}  = useParams();
  const tradeID:number = parseInt(trade_id!);
  const [editTrade, setEditTrade] = useState({} as typeTrade);
  const [tradeUpdates, setTradeUpdates] = useState({} as typeTrade);
  const [backItems, setBackItems] = useState([0,0]);
  const [updating, setUpdating] = useState(false);
  const [tradeType, setTradeType] = useState('o');
  const dispatch = useAppDispatch();  
  const atts = useSelector((state:RootState) => state.attributes);

  useEffect(() => {
    const tr = (trade.trades.find((e)=>e["id"]===tradeID) || {}) as typeTrade;
    if( tr.id === undefined ) {
      setEditTrade( {
        ...editTrade,
        trade_type:       'o',
        buy_sell:         'b',
        quantity:         1,
        trade_source_id:  1,
      });
    } else {
      setEditTrade( tr );
      setBackItems( [tr.trade_number, tr.trade_source_id ]); //Set from initial trade since trade#, sourceID can change
    }
  // eslint-disable-next-line
  },[]);

  const tradeState = useSelector((state:RootState) => state.trade )
  useEffect(() => {
    setUpdating(tradeState.isLoading)
    if( tradeState.isLoading === false && Object.keys(tradeUpdates).length > 0)  {
      if( backItems[0]===0 || backItems[1]===0 ) {
        window.location.href = `/trades/`;
      } else {
        window.location.href = `/trades/${backItems[0]}/${backItems[1]}`;
      }
    }
  //eslint-disable-next-line
  },[tradeState.isLoading]);


  const onChange = (name:string, value:any, type:string) => {
    let val = type==="number" ? value.replace(/[^0-9-.]/,'') : value;
      if( name==="trade_type" ) setTradeType( val );
      setEditTrade({
        ...editTrade,
        [name]: val
      })
      setTradeUpdates({
        ...tradeUpdates,
        [name]: val
      })
  }

  const doUpdate = () => {
    if( Object.keys(tradeUpdates).length <= 0 ) return;
    setUpdating(true);
    if( editTrade.id === undefined ) {
      dispatch( insertTrade({tradeData: editTrade}) );
    } else {
      dispatch( updateTrade({tradeID: tradeID, updateData: tradeUpdates} ));
    }
  }


  interface iExpirationDate {
    tradeType: string;
    expDate?:string|null;
    onChanged(att:string,expDate:any,type:string): void;
  }

  const RenderExpiration = (props:iExpirationDate) => {
    const {tradeType, expDate, onChanged} = props;
    let eDate = expDate ? new Date(expDate) : nextFriday();

    if( !['o','b','t','r'].includes(tradeType) ) return null;
    return(
      <tr>
        <td>Expiration Date:</td>
        <td>
          <EditDateField key="key-expd" noSpinner={true} 
            date={eDate}
            onChanged={(dt)=>onChanged("expiration_date", dt===null ? "" : dt.toString(), "date")} />
        </td>
      </tr>
    );
  }

  const handleSubmit = (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  }

  if( updating ) return (
      <Container>
        <Spinner />
      </Container>
  )

  return (
    <>
    <Container>
    <Form onSubmit={handleSubmit}>
      <Table bordered hover className="editTable">
        <tbody>
          <tr>
            <td>Trade Type:</td>
            <td>
            <DropdownList key="key-tt" cssID="dropDownListStd" data={tradeTypes} value={editTrade.trade_type || 'o'} onSelected={(sel)=>{onChange("trade_type", sel, "string")}}/>
            </td>
          </tr>
          {editTrade.trade_type==='r' ? null :
          <tr>
            <td>Trade Number:</td>
            <td><Form.Control key="key-tn" placeholder="Optional" size="sm" type="text" onChange={(evt)=>onChange("trade_number", evt.currentTarget.value, "number")} value={editTrade.trade_number||""}/></td>
          </tr>
          }
          <tr>
            <td>Trade Date:</td>
            <td><EditDateField key="key-td" noSpinner={true} date={new Date(editTrade.trade_date || (new Date()))} onChanged={(d)=>onChange("trade_date", d, "date")} /></td>
          </tr>
          {editTrade.trade_type==='r' ? null :
          <>
          <tr>
            <td>Ticker:</td>
            <td><Form.Control key="key-ticker" size="sm" type="text" onChange={(evt)=>onChange("ticker", evt.currentTarget.value, "text")} value={editTrade.ticker||""}/></td>
          </tr>
          </>
          }
          <tr>
            <td>Buy/Sell:</td>
            <td>
            <DropdownList key="key-bs" cssID="dropDownListStd" data={ {"b": "Buy", "s": "Sell"} } value={editTrade.buy_sell || 'b'} onSelected={(sel)=>{onChange("buy_sell", sel, "string")}}/>
            </td>
          </tr>
          {editTrade.trade_type==='r' ? null :
          <tr>
            <td>Quantity:</td>
            <td><Form.Control key="key-q" size="sm" type="text" onChange={(evt)=>onChange("quantity", evt.currentTarget.value, "number")} value={editTrade.quantity||""}/></td>
          </tr>
          }
          <tr>
            <td>Price:</td>
            <td><Form.Control key="k-price" size="sm" type="text" onChange={(evt)=>onChange("trade_price", evt.currentTarget.value, "number")} value={editTrade.trade_price||""}/></td>
          </tr>
          <tr>
            <td>Trade Source:</td>
            <td>
            <DropDownIDList key="key-ts" cssID="dropDownListStd" data={atts.trade_sources} value={editTrade.trade_source_id || 1} onSelected={(sel)=>{onChange("trade_source_id", sel, "number")}}/>
            </td>
          </tr>
          {editTrade.id===undefined ? null :
            <tr>
              <td>Trade Status:</td>
              <td>
                <DropdownList key="key-tstat" cssID="dropDownListStd" data={tradeStatuses} value={editTrade.trade_status || 'o'} onSelected={(sel)=>{onChange("trade_status", sel, "string")}}/>
              </td>
            </tr>
          }

          <RenderExpiration key="static-key" tradeType={tradeType} expDate={editTrade.expiration_date} onChanged={onChange}/>
          {/* Splitting options into component loses focus on drop downs? */}
          {tradeType === 'o' ?
          <>
          <tr>
            <td>Call/Put:</td>
            <td>
              <DropdownList key="key-cp" cssID="dropDownListStd" data={callPut} value={editTrade.call_put || 'p'} onSelected={(sel)=>{onChange("call_put", sel, "string")}}/>
            </td>
        </tr>
        <tr>
            <td>Structure:</td>
            <td>
              <DropDownIDList key="key-struct" cssID="dropDownListStd"  data={atts.structures} value={editTrade.structure_id || 1} onSelected={(sel)=>{onChange("structure_id", sel, "number")}}/>
              </td>
        </tr>
        <tr>
            <td>Strikes:</td>
            <td><Form.Control key="key-strikes" placeholder="Optional multiple strikes s1/s2" size="sm" type="text" onChange={(evt)=>onChange("trade_strikes", evt.currentTarget.value, "text")} value={editTrade.trade_strikes || ""}/></td>
          </tr>
          <tr>
            <td>Strike Quantities:</td>
            <td><Form.Control key="key-qs" placeholder="Optional as: 1/3/2" size="sm" type="text" onChange={(evt)=>onChange("strike_quantities", evt.currentTarget.value, "string")} value={editTrade.strike_quantities || ""}/></td>
        </tr>
        </>
        : null}

          {editTrade.id===undefined ? null :
              <tr>
                <td>Order Text:</td>
                <td>
                  <Form.Control key="key-otext" size="sm" type="text" onChange={(evt)=>onChange("order_text", evt.currentTarget.value, "string")} value={editTrade.order_text || ""} />
                </td>
              </tr>
          }
          <tr>
            <td>
              <Button key="key-button" variant="primary" onClick={()=>doUpdate()} >{editTrade.id===undefined?"Create":"Update"}</Button>
            </td>
            <td/>
          </tr>
        </tbody>
      </Table>
    </Form>
    </Container>
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  trade: state.trade as iTradesState
});

const TradeEditContrainer = connect(mapStateToProps)(TradeEdit);
export default TradeEditContrainer;