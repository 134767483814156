import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../platform/redux/store";

interface iEditTextField {
  txt: string;
  isLoading?: boolean;
  onChanged(txt:string):void;
}

const EditTextField = (props:iEditTextField) => {
  const {txt,onChanged,isLoading} = props;
  const [inputText,setInputText] = useState(txt);
  const [isUpdating,setIsUpdating] = useState(isLoading);

  const tradeState = useSelector((state:RootState) => state.trade )
  useEffect(() => {
    if( !tradeState.isLoading ) setIsUpdating(false);
  },[tradeState.isLoading]);

  const txtChange = (event: ChangeEvent<HTMLInputElement>) => setInputText(event.currentTarget.value);

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape" || event.key === "Tab") {
      event.currentTarget.value = txt; //Reset to original
      event.currentTarget.blur();
      return;
    }

    if (event.key === "Enter") {
      setIsUpdating(true);
      event.currentTarget.blur();
      onChanged(event.currentTarget.value.trim())
    }
  }

  if( isUpdating ) {
    return <Spinner animation="border" role="status" />
  } else {
    return (
      <input id="editTextField" type="text" aria-label="Field name" style={{ width: (inputText.toString().length || 10)* 10 + "px" }}
        value={inputText}
        onChange={txtChange}
        onKeyDown={onKeyDown}
      />
    )
  }

}

export default EditTextField;