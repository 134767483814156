import { Button, Container, Table } from 'react-bootstrap';
import { authRevokeSessions, iUserSession } from '../platform/redux/userSlice';
import { useAppDispatch } from '../hooks';
import { FormattedFullDate } from './FormattedDate';

export interface iSessionCard {
  sessions: iUserSession[];
}

const SessionsCard = (props:iSessionCard) : JSX.Element => {
  const {sessions} = props;
  const dispatch = useAppDispatch();  

  const revoke = () => {
    if( window.confirm("Are you sure you want to revoke session?") ) {
      dispatch( authRevokeSessions() );
    } 
  }

  if( sessions.length <= 0) return <></> ; 

  return (
    <Container>
      <Table striped bordered hover size="sm" responsive="true">
        <thead key={1}><tr><th colSpan={3} style={{textAlign:'center'}}>My Sessions</th></tr></thead>
        <thead key={3}>
          <tr>
            <th>Created</th>
            <th>Provider</th>
            <th>User Agent</th>
          </tr>
        </thead>
        <tbody>
        {
          sessions.map((sess) => {
            return (
              <tr key={new Date(sess.created).getTime()}>
                <td><FormattedFullDate date={sess.created} withTime={true}/></td>
                <td>{sess.provider}</td>
                <td>{sess.user_agent}</td>
              </tr>
            )
          })
        }
        <tr key={2}>
          <td colSpan={3} style={{textAlign:'center'}}>
            <Button variant="link" onClick={revoke}>
              Revoke All
            </Button>
          </td>
        </tr>
        </tbody>
      </Table>
    </Container>
  );
}

export default SessionsCard;