import { consoleLog, platformConfig } from './config/platform';
import FingerPrintJS from '@fingerprintjs/fingerprintjs';

export type iServerError = {
  success: boolean;
  error: {
    code: number,
    type?: string,
    message: string
  }
}

export type iServerSession = {
  token: string;
  expires: number;
  refresh_at: number;
}

export var serverSession:iServerSession = {token:"", expires:0, refresh_at:0};

export const serverLogout = () => {
  consoleLog("Reset serverSession and remove from storage...");
  serverSession = { token: "", expires: 0, refresh_at: 0 } ;
  sessionStorage.removeItem('session');
}

export const serverSessionValid = () => {
  const session = getServerSession();
  // consoleLog("Server.serverSessionValid: valid? " + (session.expires >= (new Date().getTime())).toString() );
  if( session.token === null || session.token === "" ) return false;
  if( session.expires <= (new Date().getTime()) ) return false;
  
  return true;
}
export const serverTokenValid = () => {
  if( !serverSessionValid() ) return false;
  return serverSession.refresh_at >= (new Date().getTime());
}

export const forceLoginError = ():iServerError => {
  return(
    createErrorObject(401, "Unauthorized", "Your session has expired, please re-login")
  )
}

const createErrorObject = (code:number, type:string, message:string):iServerError => {
  return (
    {
      success: false,
      error: {
        code: code,
        type: type,
        message: message
      }
    }
  );
}

const saveHeaderToken = (headers:any) => {
  const headerToken = headers.get("Refresh-Token");
  if( headerToken !== null) {
    //Server is epoch seconds, JS is epoch miliseconds
    const jToken = JSON.parse( headerToken ) ;
    jToken.expires = jToken.expires * 1000;
    jToken.refresh_at = jToken.refresh_at * 1000;

    saveSession( jToken );
  }
}

const saveSession = (session:iServerSession) => {
  serverSession = session;
  const jsonToken = JSON.stringify(session)
  // Cookies.set('session',jsonToken,{secure: true, sameSite: 'strict'} )
  sessionStorage.setItem('session', jsonToken);
}

export const getServerSession = () : iServerSession => {
  if( serverSession.token !== "" ) {
    return serverSession;
  }
  var sess = sessionStorage.getItem('session') || "";
  // var sess = Cookies.get('session') || ""
  if( sess === "" ) {
    serverSession = {token:"",expires:0,refresh_at:0} as iServerSession;
  } else {
    serverSession = JSON.parse(sess);
  }
  return serverSession;
}

const getFingerprint = async () => {
  try {
    const fpl = await FingerPrintJS.load();
    const { visitorId } = await fpl.get();
    return visitorId
  } catch(e) {
    return ""
  }
}

//Would be nice to get from redux state but couldn't get working
const setHeaders = async (form:boolean) => { 
  const {token} = getServerSession();
  const fingerPrint = await getFingerprint();
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': (form ? 'multipart/form-data' : 'application/json'),
    'Time-Zone': `{"offset": "${(new Date().getTimezoneOffset()/60*-1)}", "timezone": "${Intl.DateTimeFormat().resolvedOptions().timeZone}"}`,
    'FingerPrint': fingerPrint
  };
  return  headers;
};

const processFetch = (fetchResponse:any) => {
  return new Promise((resolve,reject) => {
    fetchResponse
    .then((response:any)=>{
      const contentType = response.headers.get("content-type");
      saveHeaderToken(response.headers);
      if (contentType && contentType.indexOf("application/json") !== -1) {
        response.json().then((json:JSON) => {
          if( response.ok ) {
            return resolve(json);
          } else {
            return reject( json );
          }        
        });
        return;
      } 
      
      response.text()
      .then((data:any)=>{
        if( response.ok ) {
          return resolve(data)
        } else {
          return reject(
            createErrorObject( response.status, response.statusText, response.statusText ) 
          );
        }
      })
    })
    .catch((errorObject:any) =>{
      return reject(
        createErrorObject( 500, 'Server Error', "Error reaching server!" ) 
      );
    });

  });
};

//So path.resolve doesn't work well. If you add /xxx the all hell breaks loose.
const fullPath = (urlPath:string) => {
  let path;
  if(urlPath.charAt(0) === '/') {
    path = `${platformConfig.apiUrl}${urlPath}`;
  } else {
    path = `${platformConfig.apiUrl}/${urlPath}`;
  }
  return path;
}

const Get = async (UrlPath:string) => {
  return processFetch(
    fetch(fullPath(UrlPath), {
      method: 'GET',
      headers: await setHeaders(false)
    })
  );  
}

const Post = async (UrlPath:string, postData:any, form=false) => {
  const data = form ? postData : JSON.stringify(postData);
  return processFetch(
    fetch(fullPath(UrlPath), {
      method: 'POST',
      headers: await setHeaders(form),
      body: data,
    })

  );  
}

const Put = async (UrlPath:string, postData:any, form=false) => {
  const data = form ? postData : JSON.stringify(postData);
  return processFetch(
    fetch(fullPath(UrlPath), {
      method: 'PUT',
      headers: await setHeaders(form),
      body: data,
    })

  );  
}

const Delete = async (UrlPath:string) => {
  return processFetch(
    fetch(fullPath(UrlPath), {
      method: 'DELETE',
      headers: await setHeaders(false)
    })

  );  
}

export {Delete, Get, Put, Post}