// store.ts
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit'
import userSlice from './userSlice';
import tradeSlice from './tradeSlice';
import errorSlice from './appErrorSlice';
import congressSlice from './congressSlice';
import fundSlice from './fundsSlice';
import attributeSlice from './attributeSlice';

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

const rootReducer = combineReducers({
  user: userSlice,
  trade: tradeSlice,
  appError: errorSlice,
  congress: congressSlice,
  fund: fundSlice,
  attributes: attributeSlice,
});

export const store = configureStore({
  reducer: rootReducer,
})