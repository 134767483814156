import { useEffect, useState } from "react";
import { Container, Spinner, Table, Image, Form } from "react-bootstrap";
import { RootState } from "../platform/redux/store";
import { useAppDispatch } from "../hooks";
import { connect } from "react-redux";
import { setAppError } from "../platform/redux/appErrorSlice";
import { getCongress, iCongressState, typeCongress } from "../platform/redux/congressSlice";
import { Post, iServerError } from "../platform/Server";
import { Link } from "react-router-dom";

interface iCongress {
  congress: iCongressState
}

const Congresses = (props:iCongress) => {
  const {congress} = props ;
  const [isBusy, setIsBusy] = useState(false);
  const [searchTxt, setSearchTxt] = useState("");
  const [congresses, setCongresses] = useState([] as typeCongress[]);
  const dispatch = useAppDispatch();  


  useEffect(() => {
    if (congress.congresses.length <= 0 && congress.isLoading === false && congress.error === null) {
      setIsBusy(true);
      dispatch( getCongress() );
    }
  //eslint-disable-next-line
  },[]);

  useEffect(() => {
    setCongresses( congress.congresses );
    setIsBusy(false)
  // eslint-disable-next-line
  },[congress.congresses]);


  const getData = (srch:string) => {
    setSearchTxt(srch)
    if( srch.length < 3 ) return;
    Post(`congresses/find`, {name: searchTxt})
      .then(data => {
        setCongresses(data as typeCongress[])
      })
      .catch((err:iServerError)=>{
        dispatch( setAppError(err) );
      })
  }

  interface iCongressRow {
    data: typeCongress;
  }
  const CongressRow = (props:iCongressRow) => {
    const {data} = props;
    return (
      <tr>
        <td>
          <Image src={data.picture} roundedCircle={true} width="20px"/>
          <Link to={`/congress/${data.id}`}> {data.last_name}, {data.first_name} </Link>
        </td>
        <td>{data.state}</td>
        <td>{data.party}</td>
        <td>{data.district}</td>
      </tr>
    );
  }

  return (
    <>
    <Container>
      <Table striped bordered hover size="sm" responsive="true">
        <thead>
          <tr style={{verticalAlign: "middle" }}>
            <th>Name</th>
            <th>State</th>
            <th>Party</th>
            <th>District</th>
          </tr>
        </thead>
        {isBusy && <Spinner />}
        <tbody>
          <tr>
            <td>
              <Form.Control type="text" onChange={(evt)=>getData(evt.currentTarget.value)} value={searchTxt}/>
            </td>
            <td colSpan={3} />
          </tr>
        {
          congresses.map(cong => {
            return <CongressRow key={cong.id} data={cong} />
          })
        }
        </tbody>
      </Table>
    </Container>
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  congress: state.congress as iCongressState,
});

const CongressContainer = connect(mapStateToProps)(Congresses)
export default CongressContainer;