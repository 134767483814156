import { KeyboardEvent, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { HashCharKey } from '../platform/redux/tradeSlice';

interface iProps {
  onSelected(val:string): void;
  data: HashCharKey;
  showAll?: boolean;
  cssID?: string;
  value?: string;
}

const DropdownList = (props:iProps) : JSX.Element => {
  const {onSelected, showAll, value, data, cssID} = props;
  const [selectItem, setSelectItem] = useState( value!==undefined ? value : "");

  const title = value!==undefined ? data[value] : data["a"] !== undefined ? data["a"] : "";

  const onSelect = (ek:any, event:Object) => {
    setSelectItem( ek as string)
    onSelected( ek as string);
  }

  const item = (key:string) => {
    if( showAll===undefined && (data[key] === "All") ) return null;
    return <Dropdown.Item key={key} eventKey={key} active={key===selectItem ? true : false} >{data[key]}</Dropdown.Item>
  }

  const keyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if( Object.keys(data).includes(event.key) ) {
      onSelect( event.key, {});
    }
  }

  return (
    <Dropdown>
      <DropdownButton title={title} id={cssID || "dropDownList"} onSelect={onSelect} onKeyUp={keyUp}>
        {
          Object.keys(data).map( (key:string) => {
            return item(key);
          })
        }
    </DropdownButton>
    </Dropdown>
  );
}

export default DropdownList;