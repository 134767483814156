import { useEffect } from "react";
import { Container, Spinner } from "react-bootstrap";
import { RootState } from "../platform/redux/store";
import { useAppDispatch } from "../hooks";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { tosLogin } from "../platform/redux/userSlice";
import { serverTokenValid } from "../platform/Server";
import { iAppErrorState } from "../platform/redux/appErrorSlice";

interface iTos {
  appError: iAppErrorState;
}

const Tos = (props:iTos) => {
  const location = useLocation();
  const dispatch = useAppDispatch();  
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const code = params.get('code') || null;

  useEffect(() => {
    if( serverTokenValid()  && code !== null) {
      dispatch( tosLogin(code) );
    } else {
      navigate('/account');
      // dispatch( setAppError({success: false, error:{code: 400, message: 'No valid code passed!'}}) )
    }
  // eslint-disable-next-line
  },[]);

  return (
    <Container>
      <Spinner />
    </Container>
  )
}

const mapStateToProps = (state: RootState) => ({
  appError: state.appError as iAppErrorState,
});

const TosContainer = connect(mapStateToProps)(Tos)
export default TosContainer;
// export default Tos;