import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CodeResponse, CredentialResponse } from '@react-oauth/google';
import { Delete, forceLoginError, Get, iServerError, Post, serverLogout, serverSession, serverSessionValid } from '../Server';
import { setAppError, clearAppError } from './appErrorSlice';
import Cookies from 'js-cookie';
import { consoleLog } from '../config/platform';
import { RootState } from './store';
import { attributesClear, attributesLoad, iAttributeTables } from './attributeSlice';

export interface iServerUser{
  success: boolean;
  logged_in: boolean;
  email: string;
  picture: string;
  last_sign_in_at: string;
  tos_account: string | null;
}

export interface iUserSession {
  created: Date;
  user_agent: string;
  provider: string;
}

export interface iUserState {
  isLoading: boolean;
  isLoggedIn: boolean;
  sessions: iUserSession[]|null;
  email: string;
  picture: string;
  last_sign_in_at: string;
  tos_account: string | null;
}

interface iTosLoginPage {
  success: boolean;
  login_page: string;
}

const saveState = (state:iUserState) => {
  var sState = {
    isLoggedIn: state.isLoggedIn,
    email: state.email,
    picture: state.picture,
    last_sign_in_at: state.last_sign_in_at,
  }
  consoleLog("saveState.cookie: ", JSON.stringify(sState));
  Cookies.set('state', JSON.stringify(sState),{secure: true, sameSite: 'strict'} )
}

export const authState = () : iUserState => {
  var uState = Cookies.get('state') || "";
  if( uState === "" ) {
    return {
      isLoggedIn: false,
      email: "",
      picture: "",
      isLoading: false,
      last_sign_in_at: "",
      sessions: null,
      tos_account: null,
    }
  } else {
    var user = JSON.parse(uState);
    consoleLog("authSlice.authState: ", user);
    return user;
  }
}
const initialState: iUserState = authState();

export const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    expire: state => {
      state.sessions = null;
      state.isLoading = false;
      state.isLoggedIn = false;
    },
    isLoading: state => {
      state.isLoading = true;
    },
    doneLoading: state => {
      state.isLoading = false;
    },
    loggedIn: (state, action: PayloadAction<iServerUser>) => {
      state.isLoading = false;
      state.isLoggedIn = true;
      state.email = action.payload.email;
      state.picture = action.payload.picture;
      state.last_sign_in_at = action.payload.last_sign_in_at;
      state.tos_account = action.payload.tos_account;
      state.sessions = null;
      saveState(state);
    },
    logOut: (state) => {
      state.isLoading = false;
      state.sessions = null;
      state.isLoggedIn = false;
      state.email = "";
      state.picture = "";
      state = initialState;
      Cookies.remove('state');
    },
    sessionsLoaded: (state, action: PayloadAction<iUserSession[]>) => {
      state.isLoading = false;
      state.sessions = action.payload;
    },
    tosLoggedIn: (state, action: PayloadAction<iServerUser>) => {
      state.isLoading = false;
      state.tos_account = action.payload.tos_account;
      saveState(state);
    },
    tosLoggedOut: (state) => {
      state.isLoading = false;
      state.tos_account = null;
      saveState(state);
    },
  }
});

const { sessionsLoaded, isLoading, loggedIn, logOut, tosLoggedIn, tosLoggedOut, expire } = authSlice.actions;
export default authSlice.reducer;

const authExpire = () => (dispatch:any) => {
  try {
    dispatch( expire() );
  } catch(err) {
  }
}

const authGetSessions = () => async (dispatch:any) => {
  try {
    dispatch( isLoading() );
    const sessions = await Get(`/sessions.json`);
    dispatch( sessionsLoaded(sessions as iUserSession[]) );
  } catch(err) {
    dispatch( setAppError(err as iServerError) );
  }
}

const authRefreshSession = createAsyncThunk(
  "user/authrefreshSession",
  async (_,{dispatch, getState}) => {
    try {
      const {appError} = getState() as RootState;;

      //Return error for setTimeout but update state,already set.
      if( !serverSessionValid() || appError.error?.code === 401 ) throw forceLoginError();

      const now = new Date().getTime();
      const refresh = serverSession.refresh_at; //Refresh time - 45 seconds
      const diff = refresh - now;

      // if( (serverSession.refresh_at - 45000) >= (new Date().getTime()) ) {
      if( diff >= 60000 ) {
        consoleLog("authSlice.authRefreshSession no refresh." + (serverSession.refresh_at) + " now: " +  (new Date().getTime()) + " remaining: " + diff.toString() + " minutes " + (diff / 1000 / 60).toString());
        return {success: true, error:{code: 200, message: "all good"}} as iServerError;
      }

      consoleLog("Get new refreshed token...");
      await Post(`/sessions/refresh.json`, "") as iServerUser;

      return {success: true, error:{code: 200, message: "all good"}} as iServerError;
  } catch(err) {
    consoleLog("authSlice.authRefreshSession.error", err)
    dispatch( expire() );
    dispatch( setAppError(err as iServerError) );
    return err as iServerError;
    }
  }
);

const authUserCred = createAsyncThunk("user/authUserCred",async (gCode:CredentialResponse,{dispatch}) => {
  try {
    dispatch( isLoading() );
    dispatch( clearAppError() );

    const user = await Post('/sessions/google/create.json?', gCode);
    dispatch(loggedIn(user as iServerUser));
    dispatch(attributesLoad(user as iAttributeTables));
    return(user);
  } catch (err) {
    dispatch( setAppError(err as iServerError) );
    return(err);
  }
});

const authUser = createAsyncThunk("user/authUser",async (gCode:CodeResponse,{dispatch}) => {
  try {
    dispatch( isLoading() );
    dispatch( clearAppError() );

    const user = await Post('/sessions/google/create.json?', gCode);
    dispatch(loggedIn(user as iServerUser));
    dispatch(attributesLoad(user as iAttributeTables));
    return(user);
  } catch (err) {
    dispatch( setAppError(err as iServerError) );
    return(err);
  }
});

/*
    This should always throw a 401 response
*/
const authRevokeSessions = () => async (dispatch:any) => {
  try {
    dispatch( isLoading() );
    await Delete(`/sessions/revoke.json?`);
    serverLogout();
    dispatch(logOut());
  } catch(err) {
    consoleLog("authSlice.authRevokeSessions.err ", err);
    let sError = err as iServerError;
    if( sError.error.code === 401 ) {
      consoleLog("authSlice.authRevokeSessions.err do logOut");
      serverLogout();
      dispatch(logOut());
      dispatch(attributesClear());
    } else {
      consoleLog("authSlice.authRevokeSessions.err failure");
      dispatch( setAppError(sError as iServerError) );
    }
  }
}

const serverTokenLogout = () => async (dispatch: any) => {
  serverLogout();
  dispatch(logOut());
}

const authLogout = () => async (dispatch: any) => {
  try {
    dispatch(isLoading());
    await Delete('/sessions/me.json');
    serverLogout();
    dispatch(logOut());
    dispatch(attributesClear());
  } catch (err) {
    let sError = err as iServerError;
    if( sError.error.code === 401 ) {
      serverLogout();
      dispatch(logOut());
    } else {
      dispatch( setAppError(err as iServerError) );
    }
  }
}

const tosLoginPage = () => async (dispatch: any) => {
  try {
    dispatch(isLoading());
    let resp = await Get('/tos/login_page.json') as iTosLoginPage;
    return resp.login_page;

  } catch (err) {
    dispatch( setAppError(err as iServerError) );
  }
}

const tosLogin = (code:string) => async (dispatch: any) => {
  try {
    dispatch(isLoading());
    var resp = await Post('/tos.json', {code: code}) as iServerUser;
    dispatch(tosLoggedIn(resp));
  } catch (err) {
    dispatch( setAppError(err as iServerError) );
  }
}

const tosLogout = () => async (dispatch: any) => {
  try {
    dispatch(isLoading());
    await Delete('/tos/me.json');
    dispatch(tosLoggedOut());
  } catch (err) {
    dispatch( setAppError(err as iServerError) );
  }
}

export {authRefreshSession, authUser, authUserCred, authGetSessions, authRevokeSessions, authLogout,serverTokenLogout, authExpire, tosLoginPage, tosLogin, tosLogout};