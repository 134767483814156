import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { iUserState } from '../platform/redux/userSlice';

interface iProps {
  user: iUserState;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const TosUserCard = (props:iProps) : JSX.Element => {
  return (
    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Img src='/images/schwab-logo.svg' />
        <Card.Title>Thinkorswim Account Link</Card.Title>
        <Card.Text>
          {/* Session Expire: {new Date( props.user.expires).toLocaleString()} */}
        </Card.Text>
        <Button variant="primary" onClick={props.onClick}>{(props.user.tos_account) ? 'Logout' : 'Login'}</Button>
      </Card.Body>
    </Card>
  );
}

export default TosUserCard;