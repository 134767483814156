import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner} from "react-bootstrap";
import { GoogleLogin, CredentialResponse, useGoogleLogin } from '@react-oauth/google';
import { connect } from 'react-redux';
import { useAppDispatch } from '../hooks';
import { RootState } from "../platform/redux/store";
import { authLogout, authUserCred, authUser, tosLogout, tosLoginPage, authGetSessions, iUserState, iUserSession } from '../platform/redux/userSlice';
import UserCard from "../components/UserCard";
import { Link, useNavigate } from "react-router-dom";
import { iAppErrorState, setAppErrorMsg } from "../platform/redux/appErrorSlice";
import TosCard from "../components/TosCard";
import SessionsCard from "../components/SessionsCard";
import { serverSession, serverTokenValid } from "../platform/Server";

interface iAccount {
  user: iUserState;
  appError: iAppErrorState;
}

const Account = (props:iAccount) => {
  const {user,appError} = props ;
  const dispatch = useAppDispatch();  
  const navigate = useNavigate();  
  const [isBusy, setIsBusy] = useState(false);
  const [sessions, setSessions] = useState([] as iUserSession[]);

  //TODO: need to tie to auth.something? 
  useEffect(() => {
    if( serverTokenValid() && !isBusy) dispatch( authGetSessions() );
    // eslint-disable-next-line
  },[user.isLoggedIn]);

  useEffect(() => {
    if( user.sessions && user.sessions.length > 0 ) setSessions( user.sessions ); 
  // eslint-disable-next-line
  },[user.sessions]);

  const gCredLogin = (response: CredentialResponse) => {
    setIsBusy(true);
    dispatch( authUserCred(response) ).then(() => {
      const lastPage = sessionStorage.getItem("lastPage");
      if( lastPage && lastPage.length > 1 ) {
        navigate(lastPage);
      } else {
        setIsBusy(false);
      }
    }).catch((error) => {
      setIsBusy(false);
    })
  };

  const gCredError = () => {
    dispatch( setAppErrorMsg("Error with login") )
  };

  const gCodeLogin = useGoogleLogin({
    onSuccess: codeResponse => {
      dispatch( authUser(codeResponse) ).then((resp) => {
        const lastPage = sessionStorage.getItem("lastPage");
        if( lastPage && lastPage.length > 1 ) {
          navigate(lastPage);
        } else {
          setIsBusy(false);
        }
        console.log(resp);
      }).catch((error) => {
        setIsBusy(false);
      })
    },
    onError: responseMessage => {
      dispatch( setAppErrorMsg(responseMessage.error_description || "Error logging in") );
    },
    onNonOAuthError(nonOAuthError) {alert("No Auth") },
      scope: 'email',
      flow: 'auth-code'
  });

  const logout = () => {
    if( window.confirm("Are you sure you want to logout?") ) {
      setIsBusy(true);
      dispatch( authLogout() ).then(()=>{
        setIsBusy(false);
      })
    } 
  }

  const tosAction = async () => {
    if( user.tos_account ) {
      if( window.confirm("Are you sure you want to unlink your TOS account?") ) {
        setIsBusy(true);
        dispatch( tosLogout() );
      } 
    } else {
      setIsBusy(true);
      var loginPage = await dispatch( tosLoginPage() );
      window.location.replace( loginPage as string);
      setIsBusy(false);
    }
  }

  const UserFragment = () => {
    if(!serverTokenValid() || appError.error?.code===401 ) {
      return (
        <React.Fragment>
          {
            serverSession.token
            ?  
              <GoogleLogin onSuccess={gCredLogin} onError={gCredError} useOneTap={true}/> 
            : 
              <Button onClick={() => gCodeLogin()}>Google Login </Button>
          }
        </React.Fragment>
      )
    } else {
      return (
        <Container>
          <Row>
            <Col>
              <UserCard user={user} serverSession={serverSession} onClick={() => logout()} />
              <Link to="/trades">My Trades</Link>
            </Col>
            <Col>
              <TosCard user={user} onClick={()=> tosAction()} />
            </Col>
          </Row>
          <br/>
          <SessionsCard sessions={sessions}/>
        </Container>
      )
    }
  }

  return (
    <Container>
      {isBusy ? <Spinner animation="border" role="status" /> : <UserFragment />}
    </Container>
  );
}

const mapStateToProps = (state: RootState) => ({
  user: state.user as iUserState,
  appError: state.appError as iAppErrorState,
});

const AccountContainer = connect(mapStateToProps)(Account);
export default AccountContainer;